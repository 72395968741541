import React, { useState } from 'react';
import { PrimaryButton, SecondaryButton } from '../buttons';
import Cookies from 'js-cookie';
import axios from 'axios';
import { API_URL } from '../../../api';
import { Flag01Icon } from 'hugeicons-react';
import { toast } from 'react-toastify';

const DeleteMeasure = ({ measureId, isOpen, onClose, removeMeasure }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const token = Cookies.get('accessToken');

    try {
const response = await axios.delete(`${API_URL}/measures/delete-measure/${measureId}/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 204) {
        removeMeasure(measureId); 
        toast.success('Measure deleted successfully!');
        
      }
    } catch (error) {
      console.error('Error deleting measure:', error.response ? error.response.data : error.message);
      setError('Failed to delete measure. Please try again.');
    } finally {
      setIsSubmitting(false);
      onClose(); 
      window.location.reload(); 
    }
  };

  if (!isOpen) return null;

  return (
    <div className="overlay">
      <div className="popup-user">
        <div className="popup-header">
          <Flag01Icon size={24} color="#FF3B30" />
          <h4 className="title">Delete</h4>
        </div>
        <p className="paragraph-red">Do you really want to delete this Measure? (With ID {measureId})</p>
        {error && <p className="error-message">{error}</p>}
        <div className="buttons">
          <SecondaryButton isLoading={false} onClick={onClose} buttonText={'Cancel'} />
          <PrimaryButton 
            isLoading={isSubmitting} 
            processingText={'Deleting'} 
            buttonText={'Delete'} 
            onClick={handleSubmit} 
          />
        </div>
      </div>
    </div>
  );
};


export default DeleteMeasure;
