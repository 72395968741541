import React from 'react'


function SingleHospitalCard({icon, value, name, id}) {
    return (
        <div className="total-card" id={id}>
          <div className="icon-container">{icon}</div>
          <div className="col">
            <p>Total {name}</p>
            <h2>{value}</h2>
          </div>
        </div>
      );
    };

export default SingleHospitalCard