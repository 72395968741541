import axios from 'axios'
import Cookies from 'js-cookie'
import { Loading02Icon, LockIcon, LockPasswordIcon, Login01Icon, Mail01Icon } from 'hugeicons-react'
import React, { useState } from 'react'
import { API_URL } from '../../api'
import toast from 'react-hot-toast'
import FromMessage from './FromMessage'
import { TextInput } from './Input'
import { PrimaryButton } from './buttons'
import { Link } from 'react-router-dom'

// const fetchUserPermissions = async(accessToken)=>{
//     const token = accessToken; 
//             const response = await axios.get(`${API_URL}/user/profile/`, {
//                 headers: {
//                     'Authorization': `Bearer ${token}`, 
//                 },
//             });
//             const user = response.data;
            
            
            
//             localStorage.setItem("position",user.position)
//             localStorage.setItem("hospital",user.hospital)
//             localStorage.setItem("hospital_id",user.hospital_id)


//             window.location.href = '/'

            
//   }
const fetchUserPermissions = async(accessToken) => {
    try {
        const token = accessToken;
        const response = await axios.get(`${API_URL}/user/profile/`, {
            headers: {
                'Authorization': `Bearer ${token}`, 
            },
        });
        const user = response.data;

        localStorage.setItem("position", user.position);
        localStorage.setItem("hospital", user.hospital);
        localStorage.setItem("hospital_id", user.hospital_id);
        window.location.href = '/';
    } catch (error) {
        console.error('Error fetching user permissions:', error);
        if (error.response && error.response.status === 403) {
            toast.error("Permission denied. Please check your account access.");
        } else {
            toast.error("An error occurred while fetching user permissions.");
        }
    }
};
const LoginForm = ({ sectionExpired }) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [formMessage, setFormMessage] = useState({})

    // const handleLogin = async (e) => {

    //     if (!email || !password) {
    //         setFormMessage('Please fill in all fields', 'error')
    //         return
    //     }
    //     e.preventDefault()
    //     try {
    //         const credentials = {
    //             "username": email,
    //             "password": password
    //         }
    //         setIsLoading(true)
    //         const response = await axios.post(`${API_URL}/accounts/token/`, credentials)
    //         // console.log(response)
    //         if (response.status === 200) {
    //             setIsLoading(false)
    //             Cookies.set('accessToken', response.data.access);
    //             Cookies.set('refreshToken', response.data.refresh);

                
    //             setIsLoading(false)
    //             toast.success("Successfully logged in")
    //             localStorage.clear()
    //             fetchUserPermissions(response.data.access)
                

                

    //             // sectionExpired === true ? window.location.reload() : window.location.href = '/'
                
                
                
                
    //         }
    //     } catch (error) {
    //         console.log(error)
    //         if (error.response) {
    //             toast.error('Error logging in. check your credentials and try again')
    //         } else {
    //             toast.error('Something went wrong. Please try again');
    //         }
    //         localStorage.clear()
    //         window.location.reload()
    //         setIsLoading(false)
    //     }
    // }
    const handleLogin = async (e) => {
        if (!email || !password) {
            setFormMessage('Please fill in all fields', 'error');
            return;
        }
        e.preventDefault();
        try {
            const credentials = {
                "username": email,
                "password": password
            };
            setIsLoading(true);
            const response = await axios.post(`${API_URL}/accounts/token/`, credentials);
            if (response.status === 200) {
                setIsLoading(false);
                Cookies.set('accessToken', response.data.access);
                Cookies.set('refreshToken', response.data.refresh);
    
                toast.success("Successfully logged in");
                localStorage.clear();
                await fetchUserPermissions(response.data.access);
            }
        } catch (error) {
            console.log(error);
            toast.error('Error logging in. Check your credentials and try again.');
            localStorage.clear();
            setIsLoading(false);
        }
    };
    return (

        <div className="form">
            <form>
                <TextInput iconClass={<Mail01Icon />} name={'email'} id={'userEmail'} type={'email'} value={email} setValue={setEmail} placeholder={'Enter your email'} />
                <TextInput iconClass={<LockPasswordIcon />} type={'password'} name={'userPassword'} id={'userPassword'} value={password} setValue={setPassword} placeholder={'Enter your password'} />
            </form>
            <PrimaryButton iconClass={<Login01Icon />} buttonText={'Login'} processingText={'Logging in'} onClick={handleLogin} isLoading={isLoading} />
            <span>Forgot password? <Link to={'/forgot-password/'} className='form-link'>Click here</Link></span>
        </div>
    )
}




export default LoginForm
