import React, { useState, useEffect } from 'react';
import { TextInput, DateInput } from '../forms/Input';
import { PrimaryButton, SecondaryButton } from '../forms/buttons';
import axios from 'axios';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import { API_URL } from '../../api';
import dayjs from 'dayjs';
import "../../../src/assets/css/popups/add.css";
import MessageDescription from "../PopoupMessage/messageDescription";
const EditPositionPopup = ({ position, onClose, onSubmit, fetchPositions }) => {
    const [positionName, setPositionName] = useState('');
    const [dateCreated, setDateCreated] = useState(dayjs());
    const [positionDescription, setPositionDescription] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (position) {
            console.log('Loading position data:', position);
            setPositionName(position.name || '');
            setDateCreated(position.date_created ? dayjs(position.date_created) : dayjs());
            setPositionDescription(position.description || '');
        }
    }, [position]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const updatePosition = {
            name: positionName,
            date_created: dateCreated.format('YYYY-MM-DD'),
details: positionDescription,
        };

        console.log("Submitting:", updatePosition);

        try {
            const token = Cookies.get('accessToken');
            const response = await axios.put(`${API_URL}/position/update_position/${position.id}/`, updatePosition, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            console.log('API response:', response);
            if (response.status === 200 || response.status === 201) {
                toast.success("Position successfully updated");
                onSubmit({ ...updatePosition, id: position.id });
                await fetchPositions();
            } else {
                toast.error("Failed to update position");
            }
        } catch (error) {
            console.error('API error:', error);
            // toast.error('Something went wrong. Please try again');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className='popup-overlay'>
           
            <form className='add-position-popup' onSubmit={handleSubmit}>
            <MessageDescription target="position" />
                <h1 className='title'>Update Role</h1>
                <TextInput
                    id="positionName"
                    name="positionName"
                    placeholder="Role Name"
                    value={positionName}
                    setValue={setPositionName}
                />
                <DateInput
                    date={dateCreated}
                    setDate={setDateCreated}
                    placeholder="Date Created"
                    label="Date Created"
                    choices={['day', 'month', 'year']}
                />
            <TextInput
    type='text'
    id='positionDescription'
    name='positionDescription'
    placeholder="Role Description"
    value={positionDescription}
    setValue={setPositionDescription}
    multiline
/>


                <div className='buttons'>
                    <SecondaryButton onClick={onClose} buttonText='Cancel' />
                    <PrimaryButton
                        isLoading={isSubmitting}
                        type='submit'
                        processingText='Submitting'
                        buttonText='Save'
                    />
                </div>
            </form>
        </div>
    );
};

export default EditPositionPopup;
