import React, { useEffect, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '../../../components/forms/buttons';
import { CleanIcon, Edit02Icon, Delete01Icon, PlusSignIcon, Search01Icon } from 'hugeicons-react';
import { TextInput } from '../../../components/forms/Input';
import api, { API_URL } from '../../../api';
import toast from 'react-hot-toast';
import FormattedDate from '../../../services/formatDate';
import NewMeasureForm from '../../../components/forms/measures/NewMeasureForm';
import DashboardContainer from '../../../components/dashboard/DashboardContainer';
import EditMeasureForm from '../../../components/forms/measures/EditMeasure';
import DeleteMeasure from '../../../components/forms/measures/DeleteMeasure';
import '../../../assets/css/pages/measures/measures.css';


const MeasuresTable = ({ measures = [], onEditClick, onDeleteClick }) => {
    const [selectedMeasures, setSelectedMeasures] = useState([]);

    const handleSelectMeasure = (measureId) => {
        const index = selectedMeasures.indexOf(measureId);
        if (index === -1) {
            setSelectedMeasures([...selectedMeasures, measureId]);
        } else {
            setSelectedMeasures(selectedMeasures.filter((id) => id !== measureId));
        }
    };

    return measures.length < 1 ? '..Nothing found' : (
        <div className="table-container">
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Measure ID</th>
                        <th>Measure Name</th>
                        <th>Category Name</th>
                        <th>Date Created</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {measures.map((measure) => (
                        <tr key={measure.id}>
                            <td><input onChange={() => handleSelectMeasure(measure.id)} type="checkbox" /></td>
<td>{measure.measure_id}</td>
                            <td>{measure.name}</td>
                            <td>{measure.category_name}</td>
                            <td><FormattedDate dateString={measure.date_created} /></td>
                            <td>
                                <Delete01Icon onClick={() => onDeleteClick(measure.measure_id)} />
                                <Edit02Icon onClick={() => onEditClick(measure.measure_id)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};


const MeasurePageContent = () => {
    const [measuresData, setMeasuresData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorFetching, setErrorFetching] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [sortedData, setSortedData] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [showNewMeasureForm, setShowNewMeasureForm] = useState(false);
    const [showEditMeasureForm, setShowEditMeasureForm] = useState(false);
    const [selectedMeasureId, setSelectedMeasureId] = useState(null);
    const [measureData, setMeasureData] = useState({});
    const [showDeleteMeasurePopup, setShowDeleteMeasurePopup] = useState(false);
    const [selectedMeasureForDeletion, setSelectedMeasureForDeletion] = useState(null);

   
    const [debounceTimeout, setDebounceTimeout] = useState(null);


    useEffect(() => {
        const fetchMeasures = async () => {
            setErrorFetching('');
            try {
                const response = await api.get(`${API_URL}/measures/`);
                if (response.status === 200) {
                    setMeasuresData(response.data);
                    setFilteredData(response.data); 
                    setSortedData(response.data);  
                    setIsLoading(false);
                }
            } catch (error) {
                toast.error(error.response?.data?.error || 'Unknown error while fetching measures');
                setErrorFetching(true);
                setIsLoading(false);
            }
        };
        fetchMeasures();
    }, []);

    // Handle search with debounce
    useEffect(() => {
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        const newTimeout = setTimeout(() => {
            setIsSearching(true);
            const filtered = searchQuery
                ? measuresData.filter(measure =>
                    measure.name.toLowerCase().includes(searchQuery.toLowerCase())
                )
                : measuresData;

            setFilteredData(filtered); 
            setSortedData(filtered); 
            setIsSearching(false);
        }, 500); // 500ms delay

        setDebounceTimeout(newTimeout);

        return () => clearTimeout(newTimeout);  // Cleanup previous timeout on each re-render
    }, [searchQuery, measuresData]);

    // Clear search and reset data
    const handleClearSearch = () => {
        setSearchQuery('');
        setFilteredData(measuresData); 
        setSortedData(measuresData);
    };

    // Handle sorting by recent or oldest
    const filterByRecent = (condition) => {
        let dataToSort = [...filteredData]; 
        
        if (condition === 'recent') {
            dataToSort.sort((a, b) => new Date(b.date_created) - new Date(a.date_created)); 
        } else if (condition === 'oldest') {
            dataToSort.sort((a, b) => new Date(a.date_created) - new Date(b.date_created));
        }

        setSortedData(dataToSort); 
    };

    const handleEditMeasure = (measureId) => {
        const measureData = measuresData.find(measure => measure.measure_id === measureId);
        setSelectedMeasureId(measureId);
        setMeasureData(measureData); 
        setShowEditMeasureForm(true);
    };

    const handleDeleteClick = (measureId) => {
        setSelectedMeasureForDeletion(measureId);
        setShowDeleteMeasurePopup(true);
    };

    const handleCloseEditForm = () => {
        setShowEditMeasureForm(false);
        setSelectedMeasureId(null);
    };

    const handleMeasureAdded = (newMeasure) => {
        setMeasuresData(prevMeasures => [...prevMeasures, newMeasure]);
        setShowNewMeasureForm(false);
    };

    return isLoading ? 'Getting data...' : errorFetching ? 'Error while getting measures' : (
        <div className='measures-page'>
            {showNewMeasureForm && (
                <div className="popup new-measure-form-popup">
                    <div className="popup-content">
                        <NewMeasureForm 
                            isOpen={showNewMeasureForm}
                            onClose={() => setShowNewMeasureForm(false)} 
                            onMeasureAdded={handleMeasureAdded} 
                        />
                    </div>
                </div>
            )}
            {showEditMeasureForm && selectedMeasureId && (
                <div className="popup edit-measure-form-popup">
                    <div className="popup-content">
                        <EditMeasureForm measureId={selectedMeasureId} measureData={measureData} onClose={handleCloseEditForm} />
                    </div>
                </div>
            )}
            {showDeleteMeasurePopup && selectedMeasureForDeletion && (
                <DeleteMeasure 
                    measureId={selectedMeasureForDeletion} 
                    isOpen={showDeleteMeasurePopup} 
                    onClose={() => setShowDeleteMeasurePopup(false)} 
                    removeMeasure={(measureId) => {
                        setMeasuresData(measuresData.filter(measure => measure.id !== measureId));
                        setShowDeleteMeasurePopup(false);
                    }} 
                />
            )}

            <div className="search-filter">
                <div className="filters">
                    <TextInput 
                        iconClass={<Search01Icon />} 
                        type='search' 
                        placeholder='Search by measure name' 
                        value={searchQuery} 
                        setValue={setSearchQuery} 
                    />
                    <PrimaryButton
            isLoading={isSearching}
            buttonText={isSearching ? 'Searching...' : 'Search'}
        />
                    {searchQuery && (
                        <SecondaryButton iconClass={<CleanIcon />} buttonText='Clear' onClick={handleClearSearch} />
                    )}
                    {isSearching && <p>Searching...</p>}
                    <select name="sort" onChange={(e) => filterByRecent(e.target.value)}>
                        <option value="">Sort by oldest</option>
                        <option value="recent">Sort by recent</option>
                    </select>
                </div>
                <div className="action">
                    {localStorage.getItem("position") === "Hospital Admin" ? '' : 
                        <PrimaryButton iconClass={<PlusSignIcon />} buttonText='New measure' onClick={() => setShowNewMeasureForm(true)} />}
                </div>
            </div>

            <div className="measures">
                <MeasuresTable measures={sortedData} onEditClick={handleEditMeasure} onDeleteClick={handleDeleteClick} />
            </div>
        </div>
    );
};



const MeasurePage = () => {
    return (
        <DashboardContainer content={<MeasurePageContent />} pageTitle={'Measured data'} />
    );
};

export default MeasurePage;
