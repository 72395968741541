import React, { useEffect } from 'react'
import LoginForm from '../../components/forms/LoginForm'
import '../../assets/css/pages/authPages/authPages.css'
import { Helmet } from 'react-helmet'
import api, { API_URL, siteTitle } from '../../api'
const LoginPage = () => {
    useEffect(() => {
        const testAPI = async () => {
            try {
                console.log('Testing...')
                const response = await api.get(`${API_URL}/dashboard/measures_chart_data/`)

                if (response.status === 200) { console.log('success: ', response.data) }
            } catch (error) {
                console.log('Error testing... ', error)
            }
        }

        testAPI()
    })
    return (
        <>
            <Helmet>
                <title>Login | {siteTitle}</title>
            </Helmet>
            <div className='auth-pages'>
                <div className="page-content">
                    <img className='logo' src="/logo192.png" alt="logo" />
                    <h2>Welcome</h2>
                    <p>Enter your email and password</p>
                    <LoginForm />
                </div>
            </div>
        </>
    )
}

export default LoginPage
