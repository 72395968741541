import { Flag01Icon } from 'hugeicons-react';
import React, { useState } from 'react';
import { PrimaryButton, SecondaryButton } from '../forms/buttons';
import Cookies from 'js-cookie';
import axios from 'axios';
import toast from 'react-hot-toast';
import { API_URL } from '../../api';

const DeleteUser = ({ userId, isOpen, onClose, deleteUser }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const token = Cookies.get('accessToken');
    
    try {
      const response = await axios.delete(`${API_URL}/user/delete_user/${userId}/`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 204) { 
        console.log('User deleted successfully:', response.data);
        deleteUser(userId); 
toast.success("User deleted successfully!");
      } 
    } catch (error) {
      console.error('Error deleting user:', error.response ? error.response.data : error.message);
      setError('Failed to delete user. Please try again.');
    } finally {
      setIsLoading(false);
      setIsSubmitting(false);
      onClose(); 
    }
  };

  return (
    <div className="overlay">
      <div className="popup-user">
        <h4 className="title">Delete</h4>
        <p className="paragraph-red">Do you really want to delete this User? (With ID {userId})</p>
    
        <div className="buttons">
          <SecondaryButton isLoading={false} onClick={onClose} buttonText={'Cancel'} />
          <PrimaryButton 
            isLoading={isSubmitting} 
            processingText={'Deleting'} 
            buttonText={'Delete'} 
            onClick={handleSubmit} 
          />
        </div>
      </div>
    </div>
  );
};

export default DeleteUser;
