import React from 'react';
import Cookies from 'js-cookie';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
// import { PermissionsProvider } from './services/permissionsContext'
import ProtectedRoute from './services/protectedRoute';
import Dashboard from './pages/dashboard/Dashboard';
import ForgetPassword from './pages/auth/ForgetPassword';
import PasswordReset from './pages/auth/PasswordReset';
import HospitalsPage from './pages/dashboard/HospitalsPage';
import UserPage from './pages/dashboard/UserPage';
import UserDetails from './pages/dashboard/UserDetails';

import TargetPage from './pages/target/TargetPage';
import './assets/css/components/buttons.css'
import './assets/css/main/main.css'
import AccountPage from './pages/account/AccountPage';

import PositionPage from './pages/dashboard/PositionPage';
import MeasurePage from './pages/dashboard/measures/MeasurePage';
import MeasureDataPage from './pages/dashboard/measures/MeasureDataPage';
import { API_URL, systemState } from './api';
import UnderMaintenance from './pages/communication/UnderMaintenance';
import DashboardContainer from './components/dashboard/DashboardContainer';
import LoginPage from './pages/auth/LoginPage';
import ProfilePage from './pages/profile/ProfilePage';
// import Cookies from 'js-cookie';


import SingleHospitalPage from './pages/singleHospital/singleHospitalPage';
import Notifications from './pages/dashboard/Notifications';
import SettingsPage from './pages/dashboard/SettingsPage';
import ReportsPage from './pages/dashboard/ReportsPage';
import UserRolesPage from './pages/dashboard/UserRolesPage';
import Hospitals from './pages/dashboard/Hospitals';
import UserDetailsPage from './pages/dashboard/UserDetails';
import axios from 'axios';
function App() {
  const canAccessSystemUnderMaintenance = Cookies.get('canAccessSystemUnderMaintenance');

  

  console.log(`Position: ${localStorage.getItem("position")}`);
  console.log(`Position: ${localStorage.getItem("hospital")}`);
  console.log(`Position: ${localStorage.getItem("hospital_id")}`);
  return (
    // <PermissionsProvider>
    <>
      <Router>
        {
          systemState === 'maintenancel' && !canAccessSystemUnderMaintenance
            ? <Routes>
              <Route path="*" element={<UnderMaintenance />} />
            </Routes>
            :
            <Routes>
              <Route 
  path="/" 
  element={
    localStorage.getItem("position") === "Hospital Admin" 
      ? <Navigate to={`/hospitals/${localStorage.getItem("hospital")}/${localStorage.getItem("hospital_id")}/`} replace />
      : <ProtectedRoute><Dashboard /></ProtectedRoute>
  }
/>

              
              {/* <Route path="/" element={<Dashboard />} /> */}
<Route path="/hospitals/" element={<ProtectedRoute><HospitalsPage /></ProtectedRoute>} />
<Route path="/all_hospitals/" element={<ProtectedRoute><Hospitals /></ProtectedRoute>} />
              <Route path="/users-list" element={<ProtectedRoute><UserPage /></ProtectedRoute>} />
              <Route path='/forgot-password/' element={<ForgetPassword />} />
              <Route path='/reset-password/' element={<PasswordReset />} />
              <Route path="/positions/" element={<ProtectedRoute><PositionPage /></ProtectedRoute>} />
              <Route path="/measures/" element={<ProtectedRoute><MeasurePage /></ProtectedRoute>} />
<Route path="/measure_data/" element={<ProtectedRoute><MeasureDataPage /></ProtectedRoute>} />
              <Route path="/notifications/" element={<ProtectedRoute><Notifications /></ProtectedRoute>} />
              <Route path="/settings/" element={<ProtectedRoute><SettingsPage /></ProtectedRoute>} />
              <Route path="/reports/" element={<ProtectedRoute><ReportsPage /></ProtectedRoute>} />
              <Route path="/users/" element={<ProtectedRoute><UserPage /></ProtectedRoute>} />
              <Route path="/user-roles" element={<UserRolesPage />} />
              <Route path="/login/" element={<LoginPage />} />
              <Route path="/hospitalscopy/" element={<Hospitals />} />
              <Route path="/targets/" element={<ProtectedRoute><TargetPage /></ProtectedRoute>} />
              <Route path='/forgot-password/' element={<ForgetPassword />} />
              <Route path='/reset-password/' element={<PasswordReset />} />
              <Route path='/hospitals/:hospitalName/:hospitalId/' element={<ProtectedRoute><SingleHospitalPage /></ProtectedRoute>} />
              <Route path='/account/' element={<AccountPage />} />

<Route path="/users/:userId" element={<UserDetailsPage />} />
              <Route path='/profilepage/' element={<ProtectedRoute><ProfilePage/></ProtectedRoute>} />

            </Routes>
        }
      </Router>
      <Toaster />
    {/* </PermissionsProvider> */}
    </>
  );
}

export default App;
