import React, { useState, useEffect, useRef } from 'react';
import { TextInput } from '../../components/forms/Input';
import axios from 'axios';
import Cookies from 'js-cookie';
import { API_URL } from '../../api';
import { PrimaryButton, SecondaryButton } from '../../components/forms/buttons';
import "../../assets/css/profile/profile.css";

const EditUser = ({ userId, userData, onClose, onSubmit, isOpen }) => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
username:'',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const popupRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose(); 
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      console.log('User data received:', userData);
      setFormData({
        first_name: userData.first_name || '',
        last_name: userData.last_name || '',
        email: userData.email || '',
username: userData.username || '',
      });
    }
  }, [userData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      const token = Cookies.get('accessToken');
      setIsLoading(true);
      const response = await axios.put(`${API_URL}/user/update_user/${userId}/`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      console.log('API response:', response);
      setSuccessMessage('User updated successfully!');
      onClose();
    } catch (error) {
      console.error('API error:', error);
    } finally {
      setIsLoading(false);
      setIsSubmitting(false);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <div className="overlay">
<div className="popup-user" >
<form onSubmit={handleSubmit} ref={popupRef} >
          <h2>Edit User</h2>
          {error && <div className="error-message">{error}</div>}
          {successMessage && <div className="success-message">{successMessage}</div>}
          <TextInput
id="username"
name="username"
placeholder="Username"
value={formData.username}
setValue={(value) => setFormData((prevFormData) => ({ ...prevFormData, username: value }))}
          />

          <TextInput
            id="first_name"
            name="first_name"
            placeholder="First Name"
            value={formData.first_name}
            setValue={(value) => setFormData((prevFormData) => ({ ...prevFormData, first_name: value }))}
          />
          <TextInput
            id="last_name"
            name="last_name"
            placeholder="Last Name"
            value={formData.last_name}
            setValue={(value) => setFormData((prevFormData) => ({ ...prevFormData, last_name: value }))}
          />
          <TextInput
            id="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            setValue={(value) => setFormData((prevFormData) => ({ ...prevFormData, email: value }))}
          />

          <div className="popup-buttons">
            <SecondaryButton onClick={handleCancel} type="button" isLoading={false} buttonText="Cancel">Cancel</SecondaryButton>
<PrimaryButton isSubmitting={isSubmitting} buttonText="Save" type="submit" processingText={'Editing User'} isLoading={isSubmitting} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUser;
