import { AlertDiamondIcon } from 'hugeicons-react';
import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../api';
import { PrimaryButton, SecondaryButton } from '../forms/buttons';
import Cookies from 'js-cookie';

const DeleteTargetPopup = ({ targetId, onClose, onDelete }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();  
        setIsSubmitting(true);
        try {
            const token = Cookies.get('accessToken');
            const response = await axios({
                method: 'delete',
url: `${API_URL}/targets/delete_target/${targetId}/`, 
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
    
            if (response.status === 200) {
                onDelete(targetId); 
                onClose();
            } else {
                console.error('Failed to delete target, status code:', response.status);
            }
        } catch (error) {
            console.error('Error deleting target:', error);
        } finally {
            setIsSubmitting(false);
        }
    };
    
    return (
        <div className='popup-overlay'>
            <form className='delete-target-popup add-target-popup' onSubmit={handleSubmit}>
                <div className='icon'><AlertDiamondIcon /></div>
                <div className='text'>
                    <h4>Delete</h4>
                    <p>Do you really want to delete the target with ID {targetId}?</p>
                </div>

                <div className='buttons'>
                    <SecondaryButton onClick={onClose} buttonText={'Cancel'} />
                    <PrimaryButton isLoading={isSubmitting} buttonText={'Delete'} />
                </div>
            </form>
        </div>
    );
};

export default DeleteTargetPopup;