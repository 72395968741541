import React, { useState, useEffect, useRef } from 'react';
import { TextInput, DataListInput, DateInput } from '../../Input';
import { PrimaryButton, SecondaryButton } from '../../buttons';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import { Flag01Icon } from "hugeicons-react";
import { API_URL } from '../../../../api';
import axios from 'axios';
import Cookies from 'js-cookie';

const AddNewMeasureData = ({ setShowNewMeasureFrom, onClose, onAddSuccess }) => {
    const [name, setName] = useState('');
    const [measure, setMeasure] = useState([]);
    const [hospital, setHospital] = useState([]);
    const [selectedHospital, setSelectedHospital] = useState('');
    const [value, setValue] = useState('');
    const [startingDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [type, setType] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [reportingDate, setReportingDate] = useState(null);

    const formRef = useRef(null);
    const [typeOptions] = useState(['numerical', 'percentage']);

    // const formatDate = (dateString) => {
    //     if (!dateString) return null;
    //     const date = new Date(dateString);
    //     date.setUTCHours(0, 0, 0, 0);
    //     return date.toISOString().split('T')[0];
    // };

    const formatDate = (dateString) => {
        if (!dateString) return null;
        const date = new Date(dateString);
        
        // Extract year, month, and day from the Date object
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
        const day = date.getDate().toString().padStart(2, '0');
        
        return `${year}-${month}-${day}`;
    };
    

    useEffect(() => {
        const fetchMeasures = async () => {
            try {
                const token = Cookies.get('accessToken');
                const response = await axios.get(`${API_URL}/measures/`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                const measuresArray = response.data.map(item => item['name']);
                setMeasure(measuresArray);
            } catch (error) {
                console.error('Error fetching measures:', error);
                toast.error('Failed to load measures');
            }
        };
        fetchMeasures();
    }, []);

    useEffect(() => {
        const fetchHospitals = async () => {
            try {
                const token = Cookies.get('accessToken');
                const response = await axios.get(`${API_URL}/hospitals/`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                const hospitalArray = response.data.map(item => item['name']);
                setHospital(hospitalArray);
            } catch (error) {
                console.error('Error fetching hospitals:', error);
                toast.error('Failed to load hospitals');
            }
        };
        fetchHospitals();
    }, []);

    const handleNewMeasureForm = async () => {
        if (!name || !measure.length || !selectedHospital) {
            toast.error('Please fill all fields');
            return;
        }
    
        setIsSaving(true);
    
        // const reportingDateFormatted = formatDate(reportingDate);
        const startingDateFormatted = formatDate(startingDate);
        const endingDateFormatted = formatDate(endDate);
        if (!startingDateFormatted && !endingDateFormatted) {
            toast.error('Invalid date format');
            setIsSaving(false);
            return;
        }
    
        const newMeasureData = {
            measure: name,
            hospital: selectedHospital,
            // reporting_date: reportingDateFormatted,
            value,
            type: 'numerical',
            starting_date:startingDateFormatted,
            end_date:endingDateFormatted
        };

        console.log(newMeasureData)
    
        try {
            const token = Cookies.get('accessToken');
            const response = await axios.post(`${API_URL}/measures/new_measure_data/`, newMeasureData, {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            });
    
            if (response.status === 201 || response.status === 200) {
                toast.success('Measure added successfully');
                
                // Ensure onAddSuccess is defined before calling it
                if (typeof onAddSuccess === 'function') {
                    onAddSuccess();
                }
                
                setShowNewMeasureFrom(false); 
                window.location.href = '/measure_data/';
            }
            
        } catch (error) {
            console.error('Error adding measure:', error.response?.data || error.message);
            toast.error('Failed to add measure. Please check the entered values.');
        } finally {
            setIsSaving(false);
        }
    };
    
    return (
        <div className='form' ref={formRef}>
<h2>New Measure Data</h2>
            <form className='new-measure-form'>
                <DataListInput
                    iconClass={<Flag01Icon />}
                    id="measure"
                    name="measureName"
                    placeholder="Measure name"
                    value={name}
                    setValue={setName}
                    options={measure || []}
                />
{/* <label>Reporting Time</label> */}
                {/* <DateInput
                    date={reportingDate}
                    setDate={setReportingDate}
                    placeholder="Reporting date"
                    label="Reporting date"
                    choices={['day', 'month', 'year']}
                /> */}
                <div className='date-range'>
                <div className='date'>
                <label>Starting Reporting Date</label>
                 <DateInput
                    date={startingDate}
                    setDate={setStartDate}
                    placeholder="Starting date"
                    label="Starting date"
                    choices={['day', 'month', 'year']}
                />
                </div>
                <div className='date'>
                <label>End Reporting Date</label>
                <DateInput
                    date={endDate}
                    setDate={setEndDate}
                    placeholder="End date"
                    label="End date"
                    choices={['day', 'month', 'year']}
                />
                </div>
               
               
                </div>
                
                <DataListInput
                    iconClass={<Flag01Icon />}
                    id="hospital"
                    name="hospitalName"
                    placeholder="Hospital"
                    value={selectedHospital}
                    setValue={setSelectedHospital}
                    options={hospital || []}
                />
                {/* <DataListInput
                    id="type"
                    name="measureType"
                    placeholder="Select Type"
                    value={type}
                    options={typeOptions} 
                    setValue={setType}
                />  */}
                <TextInput
                    type='text'
                    name='value'
                    placeholder='Value'
                    value={value}
                    setValue={setValue}
                />
            </form>
            <div className="buttons">
                <PrimaryButton
                    buttonText='Save'
                    onClick={handleNewMeasureForm}
                    isLoading={isSaving}                
                    processingText='Saving'
                />
                <SecondaryButton
                    onClick={onClose}
                    buttonText='Cancel'
                />
            </div>
        </div>
    );
}

export default AddNewMeasureData;
